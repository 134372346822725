import React from "react";
import DashboardIcon from "@material-ui/icons/DashboardOutlined";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";
import PersonIcon from "@material-ui/icons/Person";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
export default [
  {
    title: "Pages",
    pages: [
      {
        title: "Dashboard",
        href: "/user/dashboard",
        icon: DashboardIcon,
      },
      {
        title: "Leaves",
        href: "/user/leaves",
        icon: BeachAccessIcon,
      },
      {
        title: "Colleagues",
        href: "/user/users/all",
        icon: SupervisorAccountIcon,
      },
      {
        title: "Update Profile",
        href: "/user/profile/update",
        icon: PersonIcon,
      },
    ],
  },
];
