import { adminAccount, userAccounts } from "../constant/account";
import { removeTokenAndRelevantInfo } from "../utils/auth";
import MainApi from "../api/MainApi";
export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";

export const adminSignIn = (email, password, history) => async (dispatch) => {
  try {
    let invalidLogin;
    adminAccount.forEach((admin) => {
      if (admin.email === email) {
        if (admin.password === password) {
          window.localStorage.setItem("token", admin.token);
          window.localStorage.setItem("role", "admin");
          window.localStorage.setItem("user", JSON.stringify(admin));
          history.push("/admin/dashboard/question");
        } else {
          invalidLogin = true;
        }
      } else {
        invalidLogin = true;
      }
    });
    if (invalidLogin) {
      dispatch({
        type: SET_ERROR_MESSAGE,
        payload: "Invalid Login Credential!",
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const adminSignOut = (history) => async (dispatch) => {
  try {
    removeTokenAndRelevantInfo();
    window.location.href = "/";
  } catch (error) {
    console.log(error);
  }
};

export const userSignIn = (email, password, history) => async (dispatch) => {
  try {
    const response = await MainApi.post("/auth/local", {
      identifier: email,
      password: password,
    });
    window.localStorage.setItem("token", response.data.jwt);
    window.localStorage.setItem("username", response.data.user.username);
    window.localStorage.setItem("user", JSON.stringify(response.data.user));
    window.localStorage.setItem("first_name", response.data.user["first_name"]);
    window.localStorage.setItem("last_name", response.data.user["last_name"]);

    window.location.href = "/user/dashboard";
  } catch (error) {
    console.log(error);
    dispatch({
      type: SET_ERROR_MESSAGE,
      payload: "Invalid Login Credential!",
    });
  }
};

export const userSignOut = (history) => async (dispatch) => {
  try {
    removeTokenAndRelevantInfo();
    window.location.href = "/auth/user/login";
  } catch (error) {
    console.log(error);
  }
};
