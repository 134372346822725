import axios from "axios";
import { removeTokenAndRelevantInfo } from "../utils/auth";

const token = localStorage.getItem("token");
const baseUrl = process.env.REACT_APP_BASE_URL;

const MainApi = axios.create({
  baseURL: baseUrl,
});

MainApi.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// MainApi.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     if (error.response.status === 401) {
//       //place your reentry code
//       removeTokenAndRelevantInfo();
//       window.location.href = "/auth/user/login";
//     }
//     return error;
//   }
// );

export default MainApi;
